import { defineStore } from "pinia";
import { loginApi, loginApiByDD } from "@/api/account";
import prettyLog from "@/utils/print";
import * as dd from "dingtalk-jsapi";
import axios from "axios";
import { SessionStorage, Storage } from "@/utils/storage";
import router from "@/router";
const log = prettyLog();
export const useUserStore = defineStore("userStore", {
  state: () => ({
    // 用户信息
    user: {
      id: "",
      username: "",
      mobile: "",
      avatar: "",
      isAdmin: 0,
    },
    // 访问token
    token: "",
  }),
  actions: {
    setUser(val: any) {
      this.user = val;
      Storage.setItem("user", this.user);
    },
    setToken(val: any) {
      this.token = val;
      Storage.setItem("token", this.token);
    },
    // 账号登录
    async login() {
      let isDingtalk = navigator.userAgent.includes("DingTalk");
      if (!isDingtalk) {
        //15937215513
        const loginForm = { mobile: "15937215513", password: "123456" };
        const { data } = await loginApi(loginForm);
        this.setToken(data.tokenId);
        this.setUser(data);
        window.location.reload();
      } else {
        dd.ready(() => {
          dd.runtime.permission
            .requestAuthCode({
              corpId: "dingd951b7448023172c35c2f4657eb6378f",
            })
            .then(async (res) => {
              onSuccess: {
                const formData = {
                  name: "",
                  mobile: "",
                  ddUserId: "",
                  avatar: "",
                  unionId: "",
                };
                await axios
                  .post("/accountApi/account/dd/ddLogin", {
                    authCode: res.code,
                    type: "ai",
                  })
                  .then((res) => {
                    // alert("1123" + JSON.stringify(res.data));
                    formData.name = res.data.name;
                    formData.mobile = res.data.mobile;
                    formData.ddUserId = res.data.ddUserId;
                    formData.avatar = res.data.avatar;
                    formData.unionId = res.data.unionId;
                  });
                // alert("1134" + JSON.stringify(formData));
                const { data } = await loginApiByDD(formData);
                // alert("1145" + JSON.stringify(data));
                //router.push("/AiChart");
                this.setToken(data.tokenId);
                this.setUser(data);
                window.location.reload();
              }
              onFail: {
              }
            });
        });
      }
    },
    // 用户退出
    async logoutAction() {
      //await useLogoutApi();
      // 移除 token
      this.setToken(null);
      this.setUser(null);
    },
  },
});
