<template>
  <div class="sidebar">
    <el-menu>
      <el-menu-item
        class="menuClass"
        index="知识库管理"
        @click="router.push({ name: 'knowleage' })"
        ><el-icon><Setting /></el-icon>知识库管理</el-menu-item
      >
      <el-menu-item index="消耗统计" @click="router.push({ name: 'expend' })"
        ><el-icon><Document /></el-icon>消耗统计</el-menu-item
      >
      <el-menu-item index="会话列表" @click="router.push({ name: 'queslist' })"
        ><el-icon><Operation /></el-icon>会话列表</el-menu-item
      >
      <el-menu-item
        index="gpt助手管理"
        @click="router.push({ name: 'gptObjs' })"
        ><el-icon><Refresh /></el-icon> gpt助手管理</el-menu-item
      >
    </el-menu>
    <el-button type="primary" @click="router.push({ name: 'AiChart' })"
      ><el-icon><DArrowLeft /></el-icon>返回</el-button
    >
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";

const router = useRouter();
</script>
<style scoped>
.sidebar {
  position: relative;
  height: 95vh;
}
::v-deep(.el-menu) {
  border: none;
}
::v-deep(.el-button) {
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
}
</style>
