import { createApp } from "vue";
// import { QuillEditor } from "@vueup/vue-quill";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import elementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
import { createPinia } from "pinia";
import { MdPreview } from "md-editor-v3";
import moment from "moment";

import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";

import hljs from "highlight.js";

// 使用 vuepress 主题
VMdPreview.use(githubTheme, {
  Hljs: hljs,
});

// 创建打印对象
const app = createApp(App);
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$formateDate = (value: any) => {
  if (!value) return "";
  return moment(value).format("YYYY-MM-DD HH:mm:ss");
};
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(VMdPreview);
app.use(createPinia());
app.use(store);
app.use(router);
app.use(elementPlus, { locale: zhCn });
app.use(MdPreview);
app.mount("#app");
