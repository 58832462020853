import axios, { AxiosResponse } from "axios";
import { ElMessage } from "element-plus";
import { useUserStore } from "@/store/modules/user";
import { ElMessageBox } from "element-plus/es";
import prettyLog from "@/utils/print";
import router from "@/router";
const log = prettyLog();
// axios实例
const service = axios.create({
  baseURL: "",
  timeout: 60000,
  headers: { "Content-Type": "application/json;charset=UTF-8" },
});

// 请求拦截器
service.interceptors.request.use(
  (config: any) => {
    // const userStore = useUserStore();
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.token = token.slice(1, -1);
    }
    // 追加时间戳，防止GET请求缓存
    if (config.method?.toUpperCase() === "GET") {
      config.params = { ...config.params, t: new Date().getTime() };
    }
    return config;
  },
  (error: Error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  async (response: AxiosResponse<any>) => {
    //const userStore = useUserStore();
    if (response.status !== 200 && response.status !== 201) {
      ElMessage.error("请求失败，请联系管理员");

      return Promise.reject(new Error(response.statusText || "Error"));
    }
    const res = response.data;
    if (Object.prototype.toString.call(res) === "[object Blob]") {
      return response;
    }
    // 响应成功
    if (res.code === "0000") {
      return res;
    }
    // token失效，跳转到登录页
    if (res.code === "0002") {
      // alert(JSON.stringify(res));
      const userStore = useUserStore();
      userStore.login().then(() => {
        router.push("/AiChart");
      });
      // return handleAuthorized();
    }
    // 后台报错
    if (res.code === "0008") {
      ElMessage.error(res.msg || "请求失败，请联系管理员");
      // return handleAuthorized();
    }
    // 错误提示
    ElMessage.error(res.msg || "请求失败");
    return Promise.reject(new Error(res.msg || "请求失败"));
  },
  (error: Error) => {
    console.log(error);
    if (error.message.indexOf("timeout") !== -1) {
      console.log("超时超时");
      ElMessage.error("请求超时");
      return Promise.reject(new Error("请求超时"));
    } else {
      ElMessage.error(error);
      return Promise.reject(error);
    }
    // ElMessage.error("请求超时");
    // return Promise.reject("请求超时");
    // return Promise.reject(new Error("请求超时"));
    //return Promise.reject(error);
  }
);

const handleAuthorized = () => {
  ElMessageBox.confirm("登录超时，请重新登录", "提示", {
    showCancelButton: false,
    closeOnClickModal: false,
    showClose: false,
    confirmButtonText: "重新登录",
    type: "warning",
  }).then(() => {
    const userStore = useUserStore();
    // userStore?.setToken("");
    // location.reload();
    userStore.login();
    // router.push("/login");
    // return Promise.reject("登录超时，请重新登录");
  });
};

// 导出 axios 实例
export default service;
