<template>
  <!-- 最外层页面于窗口同宽，使聊天面板居中 -->
  <div class="home-view">
    <!-- 整个聊天面板 -->
    <div class="chat-panel">
      <el-row>
        <el-col :span="5">
          <!-- 左侧的会话列表 -->
          <div class="session-panel">
            <div class="title">鑫磊的AI</div>
            <div class="description"></div>
            <el-row class="mb-4">
              <el-button @click="chooseModel" type="primary">
                <el-icon :size="15">
                  <CirclePlus />
                </el-icon>
                <span style="margin-left: 5px">新的聊天</span>
              </el-button>
            </el-row>
            <div class="session-list" style="height: calc(100vh - 300px)">
              <!--              <span style="font-size: 12px">最近5条</span>-->
              <el-radio-group
                v-model="radio1"
                @change="handleChange"
                size="small"
              >
                <el-radio-button label="最近会话" />
                <el-radio-button label="我的收藏" />
                <el-radio-button label="我的分享" />
                <el-radio-button label="我收到的" />

                <!--                <el-input-->
                <!--                  v-if="radio1 == '我的收藏'"-->
                <!--                  v-model="page.name"-->
                <!--                  autocomplete="off"-->
                <!--                  @keyup.enter="handleChange"-->
                <!--                  placeholder="搜索我的收藏"-->
                <!--                  :prefix-icon="Search"-->
                <!--                  size="small"-->
                <!--                  style="margin-left: 10px; width: 160px"-->
                <!--                />-->
              </el-radio-group>
              <!-- for循环遍历会话列表用会话组件显示，并监听点击事件和删除事件。点击时切换到被点击的会话，删除时从会话列表中提出被删除的会话。 -->
              <!-- 如果处于激活状态则增加 active class -->
              <div v-for="(session, index) in sessionList" v-bind:key="index">
                <div class="session-items">
                  <div
                    :class="[
                      'session-item',
                      session.id === activeSession.id ? 'active' : '',
                    ]"
                    @click="handleSessionSwitch(session)"
                  >
                    <!-- 会话的名称 -->
                    <div class="name">{{ session.name }}</div>
                    <!-- 会话内的消息数量和最近修改的时间 -->
                    <div class="count-time">
                      <div class="count" v-if="radio1 == '我收到的'">
                        {{ session.userName ? session.userName : "" }}
                      </div>
                      <div class="count">
                        {{
                          session.type == "chartgpt" ? "ChartGPT" : "通义千问"
                        }}
                      </div>
                      <div class="time">
                        {{ formatTime(session.createTime) }}
                      </div>
                    </div>

                    <!-- 当鼠标放在会话上时会弹出遮罩 -->
                    <div class="mask"></div>
                  </div>
                  <div
                    v-if="session.id === activeSession.id"
                    style="margin: 50px 0 0 10px"
                  >
                    <el-popover
                      placement="top-start"
                      title=""
                      :width="90"
                      trigger="hover"
                    >
                      <div class="container">
                        <div class="row">
                          <el-button
                            @click="handleShare(session)"
                            link
                            :icon="Share"
                            v-if="radio1 == '最近会话' || radio1 == '我的收藏'"
                          >
                            分享会话</el-button
                          >
                        </div>
                        <div class="row">
                          <el-button
                            @click="handleDelShare(session.id)"
                            link
                            :icon="Remove"
                            v-if="radio1 == '我的分享'"
                          >
                            取消分享</el-button
                          >
                        </div>
                        <div class="row">
                          <el-button
                            @click="handleEditShare(session.id)"
                            link
                            :icon="Edit"
                            v-if="radio1 == '我的分享'"
                          >
                            修改分享</el-button
                          >
                        </div>
                        <div class="row">
                          <el-button
                            @click="handleFav(session)"
                            link
                            :icon="Star"
                            v-if="radio1 == '最近会话'"
                          >
                            收藏会话</el-button
                          >
                        </div>
                        <div class="row">
                          <el-button
                            @click="handleFavDel(session.id)"
                            link
                            :icon="StarFilled"
                            v-if="radio1 == '我的收藏'"
                          >
                            取消收藏</el-button
                          >
                        </div>
                        <div class="row">
                          <el-button
                            @click="handleDel(session.id)"
                            link
                            :icon="Delete"
                          >
                            删除会话</el-button
                          >
                        </div>
                      </div>
                      <template #reference>
                        <el-icon :size="15" v-if="radio1 != '我收到的'"
                          ><MoreFilled
                        /></el-icon>
                      </template>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
            <div class="manager-chart">
              <el-button @click="handleSetting" :icon="Setting" v-if="role">
                <!--                <el-icon :size="15">-->
                <!--                  <Setting />-->
                <!--                </el-icon>-->
                问答设置
              </el-button>
              <el-button @click="handleManage" :icon="Expand"
                >管理会话</el-button
              >
            </div>
          </div>
        </el-col>
        <el-col :span="18">
          <!-- 右侧的消息记录 -->
          <div class="message-panel">
            <!-- 会话名称 -->
            <div class="header">
              <div class="front">
                <div v-if="isEdit" class="title">
                  <el-input
                    v-model="activeSession.name"
                    @keyup.enter="handleUpdateSession"
                  ></el-input>
                </div>
                <!-- 否则正常显示标题 -->
                <div v-else class="title">
                  {{ activeSession.name }}
                  <el-icon v-if="!isEdit" @click="handleClick()">
                    <EditPen />
                  </el-icon>
                </div>
              </div>
              <div style="float: right">
                <el-avatar :src="avatar" class="avatar" shape="circle" />
              </div>
            </div>
            <el-divider :border-style="'solid'" style="margin: 5px" />
            <div class="message-list" ref="myRef">
              <!-- 过渡效果 :avatar="activeSession.createdBy.avatar"-->
              <transition-group name="list">
                <div :key="$route.path">
                  <message-row
                    v-for="(message, index) in activeSession.messages"
                    :key="index"
                    :message="message"
                  ></message-row>
                </div>
              </transition-group>
            </div>
            <!-- 监听发送事件 -->
            <message-input
              @send="handleSendMessage"
              @sendFile="sendFile"
              ref="childRef"
              :activeSession="activeSession"
            ></message-input>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      v-model="dialogVisible"
      title="管理对话记录"
      width="50%"
      :before-close="handleClose"
    >
      <!--      <el-form style="float: right" @submit.native.prevent>-->
      <!--        <el-form-item label-width="80px" prop="content">-->
      <!--          <el-input-->
      <!--            v-model="page.name"-->
      <!--            autocomplete="off"-->
      <!--            @keyup.enter="getDialogList"-->
      <!--            placeholder="搜索历史记录"-->
      <!--            :prefix-icon="Search"-->
      <!--            style="width: 200px"-->
      <!--          />-->
      <!--        </el-form-item>-->
      <!--      </el-form>-->
      <el-table
        :data="data"
        style="width: 100%; margin-top: 10px; height: 530px"
      >
        <el-table-column prop="name" label="对话名称"> </el-table-column>
        <el-table-column prop="count" label="对话次数"> </el-table-column>
        <el-table-column prop="createTime" label="发起对话时间">
          <template #default="scope">
            {{ formatdate(scope.row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="danger" @click="handleDel(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 10px"
        v-model:currentPage="page.currentPage"
        v-model:page-size="page.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        layout="prev, pager, next, sizes,jumper,total"
        :total="page.total"
        @size-change="sizeChange"
        @current-change="currentChange"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogShare" title="分享会话" width="50%">
      <el-transfer
        v-model="transVal"
        filterable
        filter-placeholder="筛选用户"
        :data="transData"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleAddShare">保存</el-button>
          <el-button @click="dialogShare = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts" setup>
import moment from "moment";
import {
  Star,
  Edit,
  Delete,
  Setting,
  Expand,
  StarFilled,
  Share,
  Remove,
} from "@element-plus/icons-vue";
import {
  getChartDetail,
  getChartsList,
  saveChart,
  sendAi,
  collectChart,
  delCollectChart,
  delChart,
  getCollectList,
  getMyShareList,
  getAllShareList,
  deleteShare,
  getUserList,
  saveChartShare,
  getShareDetail,
  saveChartDetail,
  getAssistant,
} from "@/api/Business";
import { createThread, doRun } from "@/api/NodeApi";
import { onMounted, ref, nextTick, reactive, toRefs } from "vue";
import MessageRow from "./chart/components/MessageRow.vue";
import MessageInput from "./chart/components/MessageInput.vue";
import { ElMessage } from "element-plus";
import { WebSocketClient } from "@/utils/socket";
import { ChatSession } from "@/types";
import prettyLog from "@/utils/print";
import { useRouter } from "vue-router";
import { ElMessageBox } from "element-plus/es";
import axios from "axios";
const log = prettyLog();
const myRef = ref(null);
const childRef = ref(null);
const isEdit = ref(false);
const role = ref(false);
const transVal = ref([]);
const fileIds = ref([]);
const fileNames = ref([]);
const userList = ref([]);
const radio1 = ref("最近会话");
const router = useRouter();
const dialogVisible = ref(false);
const dialogShare = ref(false);
const page = ref({ currentPage: 1, pageSize: 10, total: 0, name: "" });
const data = ref([]);
const transData = ref([{ key: "", label: "", initial: "" }]);
const state = reactive({
  avatar: JSON.parse(localStorage.getItem("user")).avatar,
  userName: JSON.parse(localStorage.getItem("user")).userName,
});
const { avatar, userName } = toRefs(state);
const activeSession = ref({ messages: [] } as ChatSession);
const sessionList = ref([] as ChatSession[]);
const threadId = ref<string>("");
const isGptFlag = ref(false);

onMounted(() => {
  if (localStorage.getItem("token")) {
    //请求会话列表
    getCharts();
    //开启socket服务
    initSocket();
    //获取gpt助手id
    getAssistantId();
    //获取登录用户的角色
    role.value = JSON.parse(localStorage.getItem("user")).isAdmin;
  }
});
const handleClick = () => {
  isEdit.value = true;
};
const handleClose = () => {
  dialogVisible.value = false;
};
const handleManage = () => {
  dialogVisible.value = true;
  getDialogList();
};
const sizeChange = (val: any) => {
  page.value.pageSize = val;
  getDialogList();
};
const currentChange = (val: any) => {
  page.value.currentPage = val;
  getDialogList();
};

const initSocket = () => {
  const client = new WebSocketClient(
    "ws://ai.yuqiinfo.com:8351/websocket/" +
      localStorage.getItem("token").slice(1, -1)
    //ai.yuqiinfo.com 192.168.8.105
  );
  client.onopen(() => {
    console.log("连接成功");
  });
  client.connect(() => {
    console.log("连接中");
  });
  client.onclose(() => {
    log.info("连接关闭");
  });
  client.onerror(() => {
    log.error("连接错误");
  });
  // 监听消息,处理业务逻辑
  client.onmessage(async (e) => {
    log.info(JSON.stringify(e.data));
    if (JSON.parse(e.data).chartId == activeSession.value.id) {
      if (!activeSession.value.messages) {
        activeSession.value.messages = [];
      }
      //删除之前的空消息
      if (
        activeSession.value.messages[activeSession.value.messages.length - 1]
          .id == -99
      ) {
        await activeSession.value.messages.pop();
      }
      const aa = JSON.parse(e.data);
      aa.flag = true;
      activeSession.value.messages?.push(aa);
    }
  });
};

const formatTime = (value: any) => {
  if (value) {
    return moment(value).format("YYYY-MM-DD HH:mm:ss");
  }
};
const scrollToBottom = () => {
  nextTick(() => {
    let scrollElem = myRef.value;
    scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: "smooth" });
  });
};

const getCharts = () => {
  // const param = { currentPage: 1, pageSize: 5 };
  sessionList.value = [];
  getChartsList({ params: { currentPage: 1, pageSize: 10 } }).then((res) => {
    sessionList.value.push(...res.data.data);
    // 默认选中的聊天会话是第一个
    if (sessionList.value.length > 0) {
      //激活会话更新为最新的那条会话
      activeSession.value = sessionList.value[0];
      isGptFlag.value = sessionList.value[0].type == "chartgpt";
      localStorage.setItem("isGptFlag", isGptFlag.value);
      getDetail(activeSession.value.id);
      log.info("激活会话:", JSON.stringify(activeSession.value));
    } else {
      ElMessage({ message: "请先创建对话", type: "warning" });
      //2、禁用文件上传、消息输入、消息发送。3、提示文件已超期，请新建会话
      childRef.value.changeEnable();
    }
  });
};
const getDialogList = () => {
  getChartsList({ params: page.value }).then((res) => {
    data.value = res.data.data;
    page.value.total = Number(res.data.totalRecords);
  });
};
const handleSetting = () => {
  router.push({ path: "/adminMain" });
};
const handleChange = () => {
  sessionList.value = [];
  if (radio1.value == "最近会话") {
    getCharts();
  }
  if (radio1.value == "我的收藏") {
    getCollectList({
      params: { currentPage: 1, pageSize: 100 },
    }).then((res) => {
      sessionList.value.push(...res.data.data);
      // 默认选中的聊天会话是第一个
      if (sessionList.value.length > 0) {
        //激活会话更新为最新的那条会话
        activeSession.value = sessionList.value[0];
        getDetail(activeSession.value.id);
        log.info("激活会话:", JSON.stringify(activeSession.value));
      } else {
        ElMessage({ message: "您还未收藏哦!", type: "warning" });
        //2、禁用文件上传、消息输入、消息发送。3、提示文件已超期，请新建会话
        childRef.value.changeEnable();
        activeSession.value.messages = [];
      }
    });
  }
  if (radio1.value == "我的分享") {
    getMyShareList({ params: { currentPage: 1, pageSize: 100 } }).then(
      (res) => {
        sessionList.value.push(...res.data.data);
        // 默认选中的聊天会话是第一个
        if (sessionList.value.length > 0) {
          //激活会话更新为最新的那条会话
          activeSession.value = sessionList.value[0];
          getDetail(activeSession.value.id);
        } else {
          ElMessage({ message: "您还未分享过哦!", type: "warning" });
          //2、禁用文件上传、消息输入、消息发送。3、提示文件已超期，请新建会话
          childRef.value.changeEnable();
          activeSession.value.messages = [];
        }
      }
    );
  }
  if (radio1.value == "我收到的") {
    getAllShareList({ params: { currentPage: 1, pageSize: 100 } }).then(
      (res) => {
        sessionList.value.push(...res.data.data);
        // 默认选中的聊天会话是第一个
        if (sessionList.value.length > 0) {
          //激活会话更新为最新的那条会话
          activeSession.value = sessionList.value[0];
          getDetail(activeSession.value.id);
          childRef.value.changeEnable();
        } else {
          ElMessage({ message: "您还未收到分享哦!", type: "warning" });
          //2、禁用文件上传、消息输入、消息发送。3、提示文件已超期，请新建会话
          childRef.value.changeEnable();
          activeSession.value.messages = [];
        }
      }
    );
  }
};
const chooseModel = () => {
  ElMessageBox.confirm(
    "若有表格解读，使用ChartGPT，其它选择通义千问",
    "请选择模型",
    {
      confirmButtonText: "通义千问",
      cancelButtonText: "ChartGPT",
      type: "warning",
      center: true,
    }
  )
    .then(() => {
      handleCreateSession(false);
    })
    .catch(() => {
      handleCreateSession(true);
    });
};
//新建对话
const handleCreateSession = (type: boolean) => {
  isGptFlag.value = type;
  localStorage.setItem("isGptFlag", isGptFlag.value);
  const chartForm = {
    name: "新会话",
    type: type ? "chartgpt" : "qwenlong",
  };
  saveChart(chartForm).then((res) => {
    if (sessionList.value.length == 10) {
      sessionList.value.splice(9, 1);
    }
    //会话列表中添加这次新建的会话
    sessionList.value.unshift(res.data);
    //设置激活会话为当前新建的会话
    activeSession.value = res.data;
    //创建新聊天后取消置灰
    childRef.value.changeEnableF();
  });
};
//更新会话名称
const handleUpdateSession = () => {
  isEdit.value = !isEdit.value;
  var chartForm = {
    id: activeSession.value.id,
    name: activeSession.value.name,
  };
  saveChart(chartForm).then(() => {
    console.log("000");
  });
};
//分享会话
const handleAddShare = () => {
  console.log(transVal.value);
  let ids = getDDids(transVal.value, userList.value);
  saveChartShare({
    chartId: activeSession.value.id,
    userIds: transVal.value,
  }).then(() => {
    ElMessage({ message: "已分享", type: "success" });
    sendMessage(ids);
    dialogShare.value = false;
  });
};
const getDDids = (arr1, arr2) => {
  // 创建一个空数组来存储结果
  const ddids = [];

  // 遍历 arr1 中的每个 id
  arr1.forEach((id) => {
    // 在 arr2 中查找具有匹配 id 的对象
    const matchedObject = arr2.find((item) => item.id === id);
    // 如果找到了匹配的对象，则将其 ddid 添加到结果数组中
    if (matchedObject) {
      ddids.push(matchedObject.ddUserId);
    }
  });

  return ddids;
};

const sendMessage = (ids: any) => {
  const params = {
    appType: "ai",
    // appUrl: "dingtalk://dingtalkclient/action/openapp?corpid=ding567e20db104f5efe35c2f4657eb6378f&container_type=work_platform&app_id=36d42891-75eb-459d-90c8-dcc69d48fa43&redirect_type=jump&redirect_url="+encodeURIComponent("http://192.168.8.104:8080/#/pages/linkMessage/linkMessage?chartId="+this.chartId),
    appUrl:
      "dingtalk://dingtalkclient/action/openapp?corpid=dingd951b7448023172c35c2f4657eb6378f&container_type=work_platform&app_id=e01b9ad2-5845-4928-85b3-f0eabbdda824&redirect_type=jump&redirect_url=" +
      encodeURIComponent(
        "http://aiapp.yuqiinfo.com/#/pages/linkMessage/linkMessage?chartId=" +
          activeSession.value.id
      ),
    ddUserIds: ids,
    imageUrl: avatar.value,
    // params: {},
    text: activeSession.value.name,
    title: "您收到来自-" + userName.value + "的分享内容",
  };
  axios.post("/accountApi/account/dd/sendMessage", params).then(() => {});
};
const sendFile = async (message: any) => {
  log.info("filemessage", activeSession.value.id);
  if (activeSession.value.id == message.chartId) {
    if (!activeSession.value.messages) {
      activeSession.value.messages = [];
    }
    (activeSession.value.messages as any[]).push({
      role: "file",
      content: message.files.name,
    });
    scrollToBottom();
  }
};

const handleSendMessage = async (message: any) => {
  if (!activeSession.messages || activeSession.messages.length == 0) {
    fileIds.value = message.fileIds;
    fileNames.value = message.fileNames;
  }
  let params = {
    chartId: activeSession.value.id,
    role: "user",
    content: message.content,
  };
  const questRow = {
    role: "user",
    content: message.content,
  };
  if (!activeSession.value.messages) {
    activeSession.value.messages = [];
  }
  // 将提问消息显示在页面中
  (activeSession.value.messages as any[]).push({
    role: "user",
    content: message.content,
    fileNames: message.file_name
      ? JSON.stringify({
          file_id: message.file_id,
          file_name: message.file_name,
          file_path: message.file_path,
        })
      : null,
  });
  scrollToBottom();
  //插入一个空消息，生成等待loading。。。
  const params_empty = { id: -99 };
  (activeSession.value.messages as any[]).push(params_empty);
  //==========================================线程id不为空，openai调用======================================================
  if (isGptFlag.value) {
    let mess = [];
    //获取历史对话
    if (activeSession.value.messages.length > 2) {
      //查询历史
      await getChartDetail(activeSession.value.id).then((res) => {
        res.data.messages.forEach((m: any) => {
          mess.push({
            role: m.role,
            content: m.content,
            attachments: m.fileNames ? JSON.parse(m.fileNames).attachments : [],
          });
        });
      });
    }
    if (message.attachments) {
      questRow.attachments = message.attachments;
    }
    mess.push(questRow);
    //创建线程
    await createThread({ messages: mess }).then((resT) => {
      threadId.value = resT.data;
      //保存会话详情
      //调用详情保存接口拼接文件
      saveChartDetail({
        chartId: activeSession.value.id,
        content: message.content,
        role: "user",
        threadId: resT.data,
        fileNames: message.file_id
          ? JSON.stringify({
              file_id: message.file_id,
              file_name: message.file_name,
              file_path: message.file_path,
              attachments: message.attachments,
            })
          : "",
      }).then((resJava) => {
        console.log(resJava.data);
      });
    });
    //清除输入框
    childRef.value.clearText();
    //获取助手id，判断localstorage有没有，没有查询，有直接用
    let assistantId = localStorage.getItem("AssistantId");
    await doRun({
      params: {
        threadId: threadId.value,
        assistantId: assistantId ? assistantId : getAssistantId(),
        chartId: activeSession.value.id,
      },
    }).then(async (resR) => {
      let messData = "";
      for (const mesRun of resR.data.messages) {
        if (mesRun.thread_id == threadId.value) {
          messData = messData + mesRun.content[0].text.value + "\n\n";
        }
      }
      //将消息打印
      await printVal(messData);
      //调用保存接口
      if (!resR.data.bId) {
        await saveDetailsApi(messData);
      }
      return;
    });
  } else {
    //清除输入框
    childRef.value.clearText();
    sendAi(params).then(() => {});
  }
};
//打印消息
const printVal = async (messData: any) => {
  console.log("最后的回复:" + messData);
  if (!activeSession.value.messages) {
    activeSession.value.messages = [];
  }
  //删除之前的空消息
  if (
    activeSession.value.messages[activeSession.value.messages.length - 1].id ==
    -99
  ) {
    await activeSession.value.messages.pop();
  }
  // const aa = JSON.parse(e.data);
  const aa = { flag: true, content: messData };
  activeSession.value.messages?.push(aa);
};
const saveDetailsApi = (content: any) => {
  saveChartDetail({
    chartId: activeSession.value.id,
    content: content,
    role: "assistant",
    threadId: threadId.value,
  }).then((resJava) => {
    console.log(resJava.data);
  });
};
//切换会话
const handleSessionSwitch = (session: any) => {
  scrollToBottom();
  activeSession.value = session;
  activeSession.value.messages = [];
  getDetail(session.id);
  // 滚动到底部
  scrollToBottom();
  if (session.isOut) {
    ElMessage({ message: "文件已超期，请新建会话", type: "warning" });
    //2、禁用文件上传、消息输入、消息发送。3、提示文件已超期，请新建会话
    childRef.value.changeEnable();
  } else if (radio1.value == "我收到的") {
    childRef.value.changeEnable();
  } else {
    childRef.value.changeEnableF();
  }
};

const getDetail = async (id: any) => {
  await scrollToBottom();
  getChartDetail(id).then((res) => {
    // 将回答消息显示在页面中
    if (res.data.messages.length > 0) {
      if (!activeSession.value.messages) {
        activeSession.value.messages = [];
      }
      activeSession.value.messages.push(...res.data.messages);
      localStorage.setItem("isGptFlag", res.data.type == "chartgpt");
      isGptFlag.value = res.data.type == "chartgpt";
      if (res.data.isOut) {
        ElMessage({ message: "文件已超期，请新建会话", type: "warning" });
        childRef.value.changeEnable();
      }
      //判断详情最后一条的角色，如果是user则往message中push一条空详情，让页面显示3个点
      if (res.data.messages[res.data.messages.length - 1].role == "user") {
        (activeSession.value.messages as any[]).push({ id: -99 });
      }
    }
    scrollToBottom();
  });
};
const handleFav = (data: any) => {
  if (data.name == "新会话") {
    ElMessageBox.prompt("请修改会话名称", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
    })
      .then(({ value }) => {
        saveChart({ id: data.id, name: value }).then(() => {
          collectChart([data.id]).then(() => {
            ElMessage({ message: "已收藏", type: "success" });
          });
        });
      })
      .catch(() => {
        ElMessage({
          type: "info",
          message: "取消修改",
        });
        return;
      });
  } else {
    collectChart([data.id]).then(() => {
      ElMessage({ message: "已收藏", type: "success" });
    });
  }
};
const handleDelShare = (id: any) => {
  deleteShare(id).then(() => {
    ElMessage({ message: "已取消分享", type: "success" });
    handleChange();
  });
};
const handleEditShare = (id: any) => {
  getUsers();
  dialogShare.value = true;
  let ids = [];
  getShareDetail(id).then((res) => {
    ids = res.data.map((item: any) => {
      return item.id;
    });
    transVal.value = ids;
  });
};
const handleShare = () => {
  dialogShare.value = true;
  transVal.value = [];
  getUsers();
};
const getUsers = () => {
  getUserList().then((res) => {
    userList.value = res.data;
    res.data.forEach((item: any) => {
      item.key = item.id;
      item.label = item.name;
      item.initial = "";
    });
    transData.value = res.data;
    console.log(transData.value);
  });
};
const handleFavDel = (id: any) => {
  delCollectChart([id]).then(() => {
    ElMessage({ message: "已取消收藏", type: "success" });
  });
};
const handleDel = (id: any) => {
  delChart(id).then(() => {
    ElMessage({ message: "已删除", type: "success" });
    dialogVisible.value = false;
    getCharts();
  });
};
//获取助手id
const getAssistantId = () => {
  getAssistant().then((res) => {
    localStorage.setItem("AssistantId", res.data.assistantId);
    return res.data.assistantId;
  });
};
const formatdate = (str: any) => {
  if (!str) {
    return "";
  }
  // 将时间戳转换为 Date 对象
  const date = new Date(str); // 注意：JavaScript中的时间戳默认以毫秒为单位

  // 获取年月日
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 加1是因为月份从0开始
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  // 返回格式化的日期
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
// const exit = () => {
//   logout().then((res) => {
//     localStorage.clear();
//     if (!localStorage.getItem("token")) {
//       ElMessage({ message: "已退出登录", type: "warning" });
//     }
//     // router.push("/login");
//   });
// };
</script>

<style lang="scss" scoped>
::v-deep(el-button) {
  border: none;
}
.home-view {
  //   width: 100vw;
  //   display: flex;
  //justify-content: center;

  .chat-panel {
    // display: flex;
    border-radius: 20px;
    // background-color: white;
    background-color: #f7f8fc;
    box-shadow: 0 0 20px 20px rgba(black, 0.05);
    margin-top: 5px;
    height: 100%;

    .session-panel {
      width: 100%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 20px;
      position: relative;
      border-right: 1px solid rgba(black, 0.07);
      background-color: rgb(255, 255, 255);
      height: 99%;
      /* 标题 */
      .title {
        margin-top: 10px;
        font-size: 20px;
        font-weight: bold;
        ::v-deep .el-input__inner {
          border: none;
        }
      }

      /* 描述*/
      .description {
        color: rgba(black, 0.7);
        font-size: 14px;
        margin-top: 10px;
      }

      .session-list {
        margin-top: 10px;
        overflow: auto;
        .session {
          /* 每个会话之间留一些间距 */
          margin-top: 20px;
        }
      }

      .button-wrapper {
        /* session-panel是相对布局，这边的button-wrapper是相对它绝对布局 */
        position: absolute;
        bottom: 20px;
        left: 0;
        display: flex;
        /* 让内部的按钮显示在右侧 */
        justify-content: flex-end;
        /* 宽度和session-panel一样宽*/
        width: 100%;

        /* 按钮于右侧边界留一些距离 */
        .new-session {
          margin-right: 20px;
        }
      }
    }

    /* 右侧消息记录面板*/
    .message-panel {
      width: 100%;
      padding: 10px 10px 10px 50px;
      background-color: #f7f8fc;
      height: 100%;

      .header {
        padding: 20px 20px 0 20px;
        display: flex;
        /* 会话名称和编辑按钮在水平方向上分布左右两边 */
        justify-content: space-between;

        /* 前部的标题和消息条数 */
        .front {
          .title {
            color: rgba(black, 0.7);
            font-size: 20px;
          }

          .description {
            margin-top: 10px;
            color: rgba(black, 0.5);
          }
        }

        /* 尾部的编辑和取消编辑按钮 */
        .rear {
          display: flex;
          align-items: center;
        }
      }

      .message-list {
        //height: 65vh;
        height: calc(100vh - 350px);
        padding: 15px;
        // 消息条数太多时，溢出部分滚动
        overflow-y: scroll;
        // overflow: hidden;
        // 当切换聊天会话时，消息记录也随之切换的过渡效果
        .list-enter-active,
        .list-leave-active {
          transition: all 0.5s ease;
        }

        .list-enter-from,
        .list-leave-to {
          opacity: 0;
          transform: translateX(30px);
        }
      }
    }
  }
}
.session-items {
  display: flex;
  justify-content: initial;
}
.session-item {
  /* 加一下padding不要让会话内容靠边界太近 */
  padding: 8px;
  background-color: #f7f8fc;
  /* 给边界一些圆角看起来圆润一些 */
  border-radius: 10px;
  /* 固定宽度 */
  width: 300px;
  /* 当鼠标放在会话上时改变鼠标的样式，暗示用户可以点击。目前还没做拖动的效果，以后会做。 */
  cursor: grab;
  /* 父相子绝，父元素是相对布局的情况下，子元素的绝对布局是相当于父元素绝对布局。 */
  position: relative;
  /* 子元素的遮罩一开始会在外面，让溢出的遮罩不显示 */
  overflow: hidden;
  margin-top: 10px;

  .name {
    /* 会话名称字体要大一些 */
    font-size: 14px;
    /* 凸显名称，加粗 */
    font-weight: 700;
    width: 200px;
    /* 加粗后颜色淡一些 */
    color: rgba(black, 0.8);
  }

  .count-time {
    /* 增加一些距离 */
    margin-top: 5px;
    /* 让字体小一些不能比会话名称要大（14px） */
    font-size: 10px;
    color: rgba(black, 0.5);
    /* 让消息数量和最近更新时间显示水平显示 */
    display: flex;
    /* 让消息数量和最近更新时间分布在水平方向的两端 */
    justify-content: space-between;
  }

  /* 当处于激活状态时增加蓝色描边 */
  &.active {
    /* 增加一些过渡 */
    transition: all 0.12s linear;
    //border: 2px solid #1d93ab;
    background-color: rgba(#409eff, 0.25);
  }

  /* 当鼠标放在会话上时触发下面的css样式*/
  &:hover {
    /* 遮罩入场，从最左侧滑进去，渐渐变得不透明 */
    .mask {
      opacity: 1;
      left: 0;
    }

    .btn-wrapper {
      /* 暗示用户这个按钮可以点击 */
      &:hover {
        cursor: pointer;
      }

      /* 按钮入场，从最右侧滑进去，渐渐变得不透明 */
      opacity: 1;
      right: 20px;
    }
  }

  .mask {
    /* 渐变样式 */
    transition: all 0.2s ease-out;
    /* 相当于父亲绝对布局 */
    position: absolute;
    background-color: rgba(#409eff, 0.25);
    /* 和父亲元素一样宽盖住父元素 */
    width: 100%;
    /* 和父亲元素一样高 */
    height: 100%;
    /*位置移到父元素的最上面 */
    top: 0;
    /* 向父元素的最左侧再增加一个父亲元素当前宽度的距离 */
    left: -100%;
    /* 透明度为0 */
    opacity: 0;
  }

  /* 删除按钮样式的逻辑和mask类似 */
  .btn-wrapper {
    color: rgba(black, 0.5);
    transition: all 0.2s ease-out;
    position: absolute;
    top: 10px;
    right: -20px;
    z-index: 10;
    opacity: 0;

    .edit {
      margin-right: 5px;
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  height: 70px; /* 可以根据需要调整高度 */
}

.row {
  flex: 1; /* 这会使得每一行平分容器的高度 */
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

.manager-chart {
  margin-top: 10px;
  //border-top: 1px solid #babbbc;
}

/* 修改垂直滚动条 */
::-webkit-scrollbar {
  width: 5px; /* 修改宽度 */
}

/* 修改滚动条轨道背景色 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* 修改滚动条滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
}

/* 修改滚动条滑块悬停时的颜色 */
::-webkit-scrollbar-thumb:hover {
  background-color: #7c7c7c;
}
</style>
