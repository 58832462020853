import service from "@/utils/request";
export const logout = () => {
  return service.post("/api//logout");
};
export const getChartsList = (params: any) => {
  return service.get("/api/chart/list", params);
};

export const saveChart = (data: any) => {
  return service.post("/api/chart/save", data);
};

export const saveChartDetail = (data: any) => {
  return service.post("/api/chart/detail/save", data);
};

export const sendAi = (data: any) => {
  return service.post("/api/ai/qwen2", data);
};

export const getChartDetail = (id: any) => {
  return service.get("/api/chart/detail/" + id);
};

export const fileUpload = (params: any) => {
  return service.post("/api/file/upload", params);
};

export const getContentList = () => {
  return service.get("/api/baseContent/list");
};
export const saveContent = (data: any) => {
  return service.post("/api/baseContent/save", data);
};
export const deleteContent = (data: any) => {
  return service.post("/api/baseContent/delete", data);
};

export const getChartApp = () => {
  return service.get("/api/chart/app");
};

export const collectChart = (data: any) => {
  return service.post("/api/chart/collect", data);
};
export const delCollectChart = (data: any) => {
  return service.post("/api/chart/collect/delete", data);
};
export const delChart = (id: any) => {
  return service.post("/api/chart/delete/" + id);
};

export const getCollectList = (params: any) => {
  return service.get("/api/chart/collectList", params);
};
export const collectQues = (data: any) => {
  return service.post("/api/chart/question", data);
};
export const useCollectQues = (id: any) => {
  return service.get("/api/chart/question/" + id);
};
export const getCollectQuesList = (param: any) => {
  return service.get("/api/chart/question", param);
};
export const getQuesList = (param: any) => {
  return service.get("/api/chart/all", param);
};
export const getUserList = () => {
  return service.get("/api/user/list");
};

export const getMyShareList = (param: any) => {
  return service.get("/api/chart/share/my", param);
};
export const saveChartShare = (data: any) => {
  return service.post("/api/chart/share", data);
};
export const getAllShareList = (param: any) => {
  return service.get("/api/chart/share/all", param);
};
export const deleteShare = (id: any) => {
  return service.get("/api/chart/share/delete/" + id);
};
export const getShareDetail = (id: any) => {
  return service.get("/api/chart/share/detail/" + id);
};
export const getAssistant = () => {
  return service.get("/api/baseContent/getAssistant");
};
export const createAssistant = () => {
  return service.get("/api/baseContent/createAssistant");
};
