import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AiChart from "@/views/AiChart.vue";
import wrap from "../views/adminView/AdminMain.vue";
import { useUserStore } from "@/store/modules/user";
import prettyLog from "@/utils/print";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/AiChart",
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/login-page.vue"),
  // },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/AiChart",
    name: "AiChart",
    component: AiChart,
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/message.vue"),
  },
  {
    path: "/adminMain",
    name: "adminMain",
    component: wrap,
    redirect: {
      name: "knowleage",
    },
    children: [
      {
        path: "knowleage",
        name: "knowleage",
        component: () => import("@/views/adminView/Knowleage.vue"),
      },
      {
        path: "queslist",
        name: "queslist",
        component: () => import("@/views/adminView/QuesList.vue"),
      },
      {
        path: "expend",
        name: "expend",
        component: () => import("@/views/adminView/Expend.vue"),
      },
      {
        path: "gptObjs",
        name: "gptObjs",
        component: () => import("@/views/adminView/GptObjs.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  routes,
});

const log = prettyLog();
// 路由跳转前
router.beforeEach(async (to, from, next) => {
  // NProgress.start();
  // const userStore = useUserStore();'
  //-----------------
  // const token = localStorage.getItem("token");
  // log.info(to.path);
  // if (to.path == "/login") {
  //   next();
  //   return;
  // }
  // if (token) {
  //   next();
  // } else {
  //   next("/login");
  // }
  //-----------------------
  async function getToken() {
    const userStore = useUserStore();

    if (!localStorage.getItem("token")) {
      await userStore.login().then(() => {
        console.log("222:" + to.path);
        if (localStorage.getItem("token")) {
          next();
        }
      });
    } else {
      next();
    }
  }
  getToken();
  console.log("111");
  // token存在的情况
  // if (to.path !== "/login" && !userStore.token) {
  //   next("/login");
  // } else {
  //   next();
  // }
});

// 路由加载后
router.afterEach(() => {
  // NProgress.done();
});

export default router;
