<template>
  <div class="wrap">
    <el-container class="container">
      <el-aside width="200px" class="asideClass">
        <Sidebar />
      </el-aside>
      <el-main class="main">
        <div class="content">
          <router-view :key="new Date().getTime()" />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import Sidebar from "./components/SideBar.vue";

const router = useRouter();
</script>
<style>
.asideClass {
  margin-right: 20px;
  border-right: 1px solid #dcdfe6;
}
</style>
