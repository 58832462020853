import service from "@/utils/request";

export const getThreadList = (params: any) => {
  return service.get("/nodeApi/thread/detail", params);
};

export const createThread = (data: any) => {
  return service.post("/nodeApi/thread", data);
};

export const doRun = (params: any) => {
  return service.get("/nodeApi/run", params);
};
