<!-- 整个div是用来调整内部消息的位置，每条消息占的空间都是一整行，然后根据right还是left来调整内部的消息是靠右边还是靠左边 -->
<template>
  <div
    :class="[
      'message-row',
      message.role === 'user' || message.role === 'file' ? 'right' : 'left',
    ]"
    style="margin-top: 10px;"
  >
    <!-- 消息展示，分为上下，上面是头像，下面是消息 -->
    <div class="row">
      <!-- 头像， -->
      <div class="avatar-wrapper" style="margin-bottom: 0px;">
        <div style="width: 40px;margin-right:10px">
        <el-avatar v-if="message.role !== 'user' && message.role !== 'file'" :src="logo" class="avatar" shape="circle" style=""/>
        </div>
      <!-- 发送的消息或者回复的消息 -->
      <div class="hover-container">
      <div  style="display: inline-block;">
        <el-popover
            placement="left-start"
            title=""
            :width="60"
            trigger="hover"
        >
          <div class="container">
              <el-button
                  @click="handleFav(text)"
                  link
                  :icon="Star"
                  v-if="message.role === 'user'"
              >
                收藏问题</el-button
              >
            <div class="rows">
              <el-button
                  @click="handleDownLoad(message.content)"
                  link
                  v-if="message.role === 'file'"
              >
                <el-icon style="margin-right:5px"><Download /></el-icon>下载文件</el-button
              >
            </div>
            <div class="rows">
              <el-button
                  @click="handleView(message.content)"
                  link
                  v-if="message.role === 'file'"
              >
                <el-icon style="margin-right:5px"><View /></el-icon>预览文件</el-button
              >
            </div>


          </div>
          <template #reference>
            <el-icon class="hover-button" v-if="message.role === 'user' || message.role === 'file'"><MoreFilled /></el-icon>
          </template>
        </el-popover>
      </div>

      <div class="message"  >
        <!-- 预览模式，用来展示markdown格式的消息 -->
        <div v-if="gptText && message.fileNames " @click="handleViewGpt(message.fileNames)">
          <img :src="excel" style="width: 45px;height:45px"></img>
          {{ gptText }}
        </div>
<!--        <MdPreview-->
<!--          v-if="text && message.role !== 'file'"-->
<!--          :id="'preview-only'"-->
<!--          :model-value="text"-->
<!--          :preview-theme="'smart-blue'"-->
<!--          >{{ text }}</MdPreview>-->

<!--        <v-md-editor v-model="text" height="400px"></v-md-editor>-->
        <v-md-preview v-if="text && message.role !== 'file'" :text="text"></v-md-preview>
        <div v-if="text && message.role == 'file'" @click="handleView(message.content)">
          <img :src="excel" style="width: 45px;height:45px"></img>
          {{ text }}
        </div>

        <!-- :style="{
            backgroundColor: message.role === 'user' ? 'rgb(231, 248, 255)' : '#f4f4f5'
          }" -->
        <!-- 如果消息的内容为空则显示加载动画 -->
        <TextLoading v-if="!text && !gptText"></TextLoading>
      </div>
    </div>
        <el-avatar
            v-if="message.role === 'user' || message.role === 'file'"
            :src="message.avatar ? message.avatar : avatar"
            class="avatar"
            shape="circle"
            style="margin-left:10px"
        />
    </div>
    </div>

  </div>
  <el-dialog
      title="预览"
      v-model="dialogVisibleRow"
      width="90%"
      >
    <div >
      <iframe
          class="child"
          :src="downloadUrl"
          :style="{ width: '100%', height: '700px' }"
      > </iframe>
    </div>
  </el-dialog>
</template>
<script>
import TextLoading from "./TextLoading";
import { MdPreview } from "md-editor-v3";
import {Delete, Star, StarFilled,Download,View} from "@element-plus/icons-vue";
import {collectQues} from "@/api/Business";
import {ElMessage} from "element-plus";
// import 'prismjs/themes/prism-tomorrow.css';

import {reactive} from "vue";
export default {
  props: {
    message: {
      type: Object,
      require,
    },
  },
  data() {
    return {
      avatar: JSON.parse(localStorage.getItem("user")).avatar,
      logo: require("./../../../../assets/AI.png"),
      excel: require("./../../../../assets/doc3.png"),
      text: "",
      gptText:"",
      index: 0,
      timer: null,
      textObj: null,
      dialogVisibleRow:false,
      downloadUrl:''
    };
  },
  components: {
    TextLoading,
  },
  computed: {
    Delete() {
      return Delete
    },
    StarFilled() {
      return StarFilled
    },
    Star() {
      return Star
    }
  },
  watch: {},
  created() {
    if (this.message.flag) {
      this.timer = setInterval(this.type, 50); // 每200毫秒添加一个字符
    } else {
      if(this.message.fileNames ){
        if(null == this.message.fileIds){
          console.log(JSON.parse( this.message.fileNames).file_name)
          this.gptText =JSON.parse( this.message.fileNames).file_name;
          this.text = this.message.content;
        }else{
          this.text=this.message.fileNames
        }
      }else{
        this.text = this.message.content;
      }
    }
  },
  methods: {
    type() {
      if (this.index < this.message.content.length) {
        this.text += this.message.content[this.index++];
      } else {
        this.index = 0;
        clearInterval(this.timer);
      }
    },
    handleViewGpt(jsonStr){
      let path = JSON.parse(jsonStr).file_path
      this.dialogVisibleRow = true
      // this.downloadUrl="https://view.officeapps.live.com/op/view.aspx?src=http://ai.yuqiinfo.com/uploadfile"+path
      this.downloadUrl = "https://view.xdocin.com/view?src=http://yul.yuqiinfo.com/aiupload"+path.split("..").pop()
      console.log(this.downloadUrl)

    },
    handleView(path){
        this.dialogVisibleRow = true
        // this.downloadUrl="https://view.officeapps.live.com/op/view.aspx?src=http://ai.yuqiinfo.com/uploadfile"+path
        this.downloadUrl = "https://view.xdocin.com/view?src=http://yul.yuqiinfo.com/aiupload"+path.split("..").pop()
        console.log(this.downloadUrl)

    },
    handleDownLoad(path){
      let a = document.createElement('a')
      a.href = "http://ai.yuqiinfo.com/uploadfile"+path
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },

    handleFav(text){
      collectQues({content:text}).then((res) => {
        ElMessage({ message: "已收藏", type: "success" });
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.message-row {
  display: flex;

  &.right {
    // 消息显示在右侧
    justify-content: flex-end;

    .row {
      // 头像也要靠右侧
      .avatar-wrapper {
        display: flex;
        justify-content: flex-end;
      }

      // 用户回复的消息和ChatGPT回复的消息背景颜色做区分
      .message {
        background-color: rgba(#409eff,0.25);
        display: inline-block;
        width: auto;
      }
    }
  }
  .rows {
    flex: 1; /* 这会使得每一行平分容器的高度 */
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
  }
  // 默认靠左边显示
  .row {
    .avatar-wrapper {
      .avatar {
        box-shadow: 20px 20px 20px 3px rgba(0, 0, 0, 0.03);
        margin-bottom: 5px;

      }
      display: flex;
      justify-content: flex-end;
    }

    .message {
      // font-size: 15px;
      // padding: 16px;
      // // 限制消息展示的最大宽度
      // // max-width: 500px;
      // // 圆润一点
      // border-radius: 7px;
      // // 给消息框加一些描边，看起来更加实一些，要不然太扁了轻飘飘的。
      // border: 1px solid rgba(black, 0.1);
      // // 增加一些阴影看起来更加立体
      // box-shadow: 20px 20px 20px 1px rgba(0, 0, 0, 0.01);
      background: #fff;
     // max-width: 80%;
      border: 1px solid transparent;
      border-radius: 16px;
      display: inline-block;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      // min-height: 60px;
      overflow: hidden;
      padding: 5px;
      position: relative;
    }
  }
}

.hover-button {
  opacity: 0;
  pointer-events: none; /* 阻止点击事件 */
  transition: opacity 0.3s; /* 平滑过渡效果 */
}
.hover-container:hover .hover-button {
  opacity: 1;
  pointer-events: auto; /* 允许点击事件 */
}
// 调整markdown组件的一些样式，deep可以修改组件内的样式，正常情况是scoped只能修改本组件的样式。
:deep(.md-editor-preview-wrapper) {
  padding: 0 10px;

  .smart-blue-theme p {
    line-height: unset;
  }
}

:deep(.github-markdown-body){
  padding: 10px 16px 0px;
  .p{
    padding: 0px;
    margin-bottom: 0px;
  }
}
</style>
