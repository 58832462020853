<template>
  <div class="message-input">
    <el-row>
      <el-col :span="8">
        <div v-if="showFiles">
          <el-icon :size="20" color="#409efc"><Document /></el-icon>
          <span>{{ message.file_name }}</span>
        </div>
      </el-col>
      <el-col :span="16">
        <!--    用户收藏的问题-->
        <div style="margin: 0 0 5px 20px">
          <span v-for="(item, index) in quesList" v-bind:key="index">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="item.content"
              placement="top-start"
            >
              <el-tag style="margin-left: 5px" @click="useQues(item)">{{
                item.content.length > 10
                  ? item.content.slice(0, 10) + "..."
                  : item.content
              }}</el-tag>
            </el-tooltip>
          </span>
        </div>
      </el-col>
    </el-row>

    <div class="input-wrapper">
      <!-- 按回车键发送，输入框高度三行 -->

      <el-input
        v-model="message.content"
        autosize
        class="input"
        resize="none"
        @keydown.enter="sendMessage"
        placeholder="输入与本会话相关的问题，非相关问题请新建会话"
        :disabled="btnEnable"
      >
        <template #prepend>
          <el-upload
            class="upload-demo"
            action
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :accept="acceptType"
            multiple
            :limit="10"
            :on-exceed="handleExceed"
            :file-list="files"
            :show-file-list="false"
            :http-request="upLoad"
            :before-upload="beforeUpload"
          >
            <el-button
              type="primary"
              class="el-icon-paperclip"
              :disabled="btnEnable"
              @click="clickUpload"
              ><el-icon :size="20" :color="btnEnable ? '#b1b4b7' : '#409EFC'"
                ><UploadFilled /></el-icon
              >上传文件</el-button
            >
          </el-upload>
        </template>
        <template #append>
          <el-button @click="sendMessage" :disabled="btnEnable">
            <el-icon :size="20" :color="btnEnable ? '#b1b4b7' : '#409EFC'"
              ><Promotion
            /></el-icon>
          </el-button>
        </template>
      </el-input>
    </div>
    <p style="margin-left: 20px">
      注：若上传的excel中有多个sheet页，提问时注明sheet页的名称结果更准确！
    </p>
  </div>
</template>

<script>
import axios from "axios";
import { useCollectQues, getCollectQuesList } from "@/api/Business";
export default {
  props: {
    activeSession: {
      type: Object,
      require,
    },
  },
  data() {
    return {
      message: {
        content: "",
        fileIds: [],
        fileNames: [],
        formData: {},
        file_id: "", //chartGpt
        file_name: "", //chartGpt
        file_path: "", //chartGpt
        attachments: [],
      },
      files: [],
      quesList: [],
      btnEnable: false,
      isoutEnable: false, //超期后不能发送和上传文件
      showFiles: false,
      acceptType:
        localStorage.getItem("isGptFlag") == "true"
          ? ".xlsx,.xlsm,.xls"
          : ".pdf,.txt,.docx,.doc,.xlsx,.xlsm,.xls",
    };
  },
  components: {},
  computed: {},
  created() {
    getCollectQuesList().then((res) => {
      this.quesList = res.data.data;
    });
  },
  watch: {
    activeSession: {
      handler(val) {
        if (
          val.messages &&
          val.messages[val.messages.length - 1] &&
          val.messages[val.messages.length - 1].id !== -99
        ) {
          if (!this.isoutEnable) {
            this.btnEnable = false;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    // 发送消息消息事件
    sendMessage() {
      console.log("this.message==", this.message);
      if (!this.message.content) {
        this.$message.success("请输入内容");
        return;
      }
      if (
        this.activeSession.messages &&
        this.activeSession.messages[this.activeSession.messages.length - 1]
          ? this.activeSession.messages[this.activeSession.messages.length - 1]
              .id === -99
          : false
      ) {
        this.btnEnable = true;
        return;
      }
      //文件已上传，需要一并发送给chart作保存使用
      this.$emit("send", this.message);
      // 发送完清除

      this.showFiles = false;
    },
    //使用问题
    useQues(item) {
      useCollectQues(item.id).then((res) => {});
      this.message.content = item.content;
    },
    clearFiles() {
      this.files = [];
    },
    changeEnable() {
      this.isoutEnable = true;
      this.btnEnable = true;
    },
    changeEnableF() {
      this.isoutEnable = false;
      this.btnEnable = false;
    },
    clearText() {
      this.message.content = "";
      this.message.file_id = "";
      this.message.file_name = "";
      this.message.file_path = "";
      this.message.attachments = [];
    },
    // 上传文件
    upLoad(item) {
      console.log("========", item);
      this.btnEnable = true;
      // item 是当前本地上传的文件
      this.message.fileNames.push(item.file.name);
      const formData = new FormData();
      formData.append("file", item.file);
      formData.append("chartId", this.activeSession.id);
      if (localStorage.getItem("isGptFlag") == "true") {
        this.uploadOpenai(item);
        return;
      }
      //保存文件
      axios
        .post("/api/file/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token").slice(1, -1),
          },
        })
        .then((res) => {
          console.info(res.data.data);
          this.$emit("sendFile", {
            chartId: this.activeSession.id,
            role: "file",
            files: {
              id: res.data.data.id,
              name: item.file.name,
              path: res.data.data.content,
            },
          });
          // this.message.fileIds.push(res.data.data);
          this.btnEnable = false;
          this.clearFiles();
          this.$message.success("文件上传成功");
        });
    },
    uploadOpenai(item) {
      const formData = new FormData();
      formData.append("file", item.file);
      axios
        .post("/nodeApi/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resUp) => {
          let that = this;
          console.log(resUp.data);
          if ((resUp.data.code = "0000")) {
            //上传成功 调用java上传文件
            const openForm = new FormData();
            openForm.append("file", item.file);
            openForm.append("chartId", that.activeSession.id);
            openForm.append("fileId", resUp.data.data);
            axios
              .post("/api/file/upload/openai", openForm, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  token: localStorage.getItem("token").slice(1, -1),
                },
              })
              .then((res) => {
                console.log(res.data);
                this.message.file_id = res.data.data.file_id;
                this.message.file_name = res.data.data.file_name; //输入框上面显示文件名字
                this.message.file_path = res.data.data.file_path;
                this.message.attachments.push({
                  file_id: resUp.data.data,
                  tools: [{ type: "code_interpreter" }],
                });

                this.showFiles = true; //显示图标
                this.btnEnable = false;
                this.clearFiles();
                this.$message.success("文件上传成功");
              });
          } else {
            this.$message.error("文件上传失败" + res.data.msg);
          }
        });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log("----");
    },
    clickUpload() {
      this.acceptType =
        localStorage.getItem("isGptFlag") == "true"
          ? ".xlsx,.xlsm,.xls"
          : ".pdf,.txt,.docx,.doc,.xlsx,.xlsm,.xls";
    },
    beforeUpload(file) {
      const MAX_SIZE = 1024 * 1024 * 10; // 设定最大文件大小为100MB
      if (file.size > MAX_SIZE) {
        this.$message.error("文件大小不能超过10MB!");
        return false; // 返回false停止上传
      }
      return true; // 返回true允许上传
    },
    handleExceed(files, fileList) {
      this.btnEnable = true;
      //this.$message.warning(`本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      this.files = this.files.filter((f) => f.name != file.name);
      this.data.files = this.files;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn_message {
  margin-left: 5px;
}
.message-input {
  padding: 10px;
  //border-top: 1px solid rgba(black, 0.07);
  //border-left: 1px solid rgba(black, 0.07);
  //border-right: 1px solid rgba(black, 0.07);
  //border-top-right-radius: 5px;
  //border-top-left-radius: 5px;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  float: right;
}
.input-wrapper {
  display: inline;
}
.el-input {
  margin-left: 20px;
  width: 95%;
  height: 50px;
}
</style>
